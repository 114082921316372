import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
    selector: 'app-warning-hex',
    standalone: true,
    imports: [CommonModule],
    templateUrl: './warning-hex.component.html',
    styles: [],
})
export class WarningHexComponent {}
