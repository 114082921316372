<svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="transparent"
    stroke="currentColor"
    xmlns="http://www.w3.org/2000/svg">
    <path
        d="M9.99981 6.45817V10.8332M17.5665 7.14984V12.8498C17.5665 13.7832 17.0665 14.6498 16.2581 15.1248L11.3081 17.9832C10.4998 18.4498 9.49981 18.4498 8.68314 17.9832L3.73314 15.1248C3.33462 14.8938 3.00392 14.5619 2.77427 14.1626C2.54461 13.7632 2.42409 13.3105 2.42481 12.8498V7.14984C2.42481 6.2165 2.92481 5.34984 3.73314 4.87484L8.68314 2.0165C9.49148 1.54984 10.4915 1.54984 11.3081 2.0165L16.2581 4.87484C17.0665 5.34984 17.5665 6.20817 17.5665 7.14984Z"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round" />
    <path d="M10 13.5V13.5833" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
</svg>
